import 'vite/modulepreload-polyfill';
import '@tabler/core/src/js/tabler.js';

import '../js/main.js';
import '../js/common/themePage.js';
import '../js/algolia/app.js';
import '../js/common/notifications.js';
// @ts-ignore
import white_logo_tagline from '../icon/en/logo/white_logo_tagline.png'
import {Notyf} from "notyf";

export const notyf = new Notyf(
    {
        duration: 0,
        dismissible: true,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'success',
                duration: 3000,
                background: '#2fb344',
                icon: {
                    className: "ti ti-circle-check-filled tb-alert-i",
                    tagName: "i",
                    color: "white",
                }
            },
            {
                type: 'info',
                background: '#4299e1',
                duration: 3000,
                icon: {
                    className: "ti ti-info-circle-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            },
            {
                type: 'warning',
                background: '#f76707',
                icon: {
                    className: "ti ti-alert-circle-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            },
            {
                type: 'error',
                background: '#d63939',
                icon: {
                    className: "ti ti-xbox-x-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            }
        ]
    }
);

// @ts-ignore
(window as any).notyf = notyf;

type SelectElement = HTMLSelectElement | null;

const brandLogo = document.getElementById('navbar-brand-image') as HTMLImageElement | null;
if (brandLogo && !brandLogo.src) {
    brandLogo.src = white_logo_tagline;
}

window.onload = () => {
    // Get the select elements
    let sourceSelect: SelectElement = document.getElementById('id_source') as SelectElement;
    let searchSelect: SelectElement = document.getElementById('id_search') as SelectElement;


    // Define the function to submit the form
    const submitForm = () => {
        let form = document.getElementById('filter-form') as HTMLFormElement;
        form?.submit();
    };

    // Add event listeners
    sourceSelect?.addEventListener('change', submitForm);
    searchSelect?.addEventListener('change', submitForm);
};


document.addEventListener("DOMContentLoaded", () => {
    let deleteEntityModal = document.getElementById('deleteEntity');
    if (deleteEntityModal) {
        deleteEntityModal.addEventListener('show.bs.modal', (event: any) => {
            let button = event.relatedTarget;
            let url = button.getAttribute('data-bs-url');

            if (deleteEntityModal) {

                let form = deleteEntityModal.querySelector<HTMLFormElement>('form');

                if (form) {

                    form.action = url;

                }
            }
        });
    }
});

