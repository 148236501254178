import instantsearch from "instantsearch.js";
import {history,} from 'instantsearch.js/es/lib/routers'

import {searchClient, algoliaIndexName} from "./searchClient";
import {generalWidgets} from "./generalWidgets";

const hits = document.getElementById("hits");
const searchPage = hits.dataset.searchPage === "true";
const dashboardPage = hits.dataset.dashboardPage === "true";

export const instantSearchRouter = history({
    cleanUrlOnDispose: false,
});

export const search = instantsearch({
    indexName: algoliaIndexName,
    searchClient,
    stalledSearchDelay: 500,
    future: {
        preserveSharedStateOnUnmount: true,
    },
    routing: {
        router: instantSearchRouter,
    },
    numberLocale: "en-US",
});


search.addWidgets(generalWidgets);

async function setupSearchWidgets() {
    if (searchPage) {
        const searchPageWidgets = await import("./searchPage");
        search.addWidgets(searchPageWidgets.widgets);
    }
    if (dashboardPage) {
        const dashboardPageWidgets = await import("./dashboardPage");
        search.addWidgets(dashboardPageWidgets.widgets);
    }
}


setupSearchWidgets();
