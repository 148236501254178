import {connectHits} from "instantsearch.js/es/connectors";
import {algoliaIndexName} from "./../searchClient";
import {html} from 'htm/preact';

const language = "en";

const renderHits = (renderOptions, isFirstRender) => {
    const {hits, results, sendEvent, widgetParams} = renderOptions;

    if (isFirstRender) {
        return;
    }

    widgetParams.container.innerHTML = html`
        ${hits
                .map(
                        (item) =>
                                `<a class="dropdown-item custom-dropdown-item" href="/${language}/search/?${algoliaIndexName}[query]=${item.title}" rel="noopener">${item.title}</a>`
                )
                .join("")}
    `;
};

const customHits = connectHits(renderHits);

export const headHits = customHits({
    container: document.querySelector("#hits"),
});
