(() => {
  "use strict";

  const THEME_KEY = "tablerTheme";
  const urlParams = new URLSearchParams(window.location.search);

  // Get the theme from URL parameters or local storage
  const theme = urlParams.get('theme') || localStorage.getItem(THEME_KEY) || "light";

  // Update the local storage and apply the theme
  localStorage.setItem(THEME_KEY, theme);
  if (theme === "dark") {
    document.body.setAttribute("data-bs-theme", "dark");
  } else {
    document.body.removeAttribute("data-bs-theme");
  }
})();
