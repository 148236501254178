function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function removeUnreadDot() {
  const notificationItems = document.querySelectorAll(".notification-item");

  notificationItems.forEach((item) => {
    const spans = item.querySelectorAll("span");
    spans.forEach((span) => span.parentNode.removeChild(span));
  });

  const unreadDotIcon = document.getElementById("span-dot-not-icon");
  if (unreadDotIcon) {
    unreadDotIcon.parentNode.removeChild(unreadDotIcon);
  }
}

function markAllAsRead(url) {
  fetch(url, {
    method: "POST",
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        removeUnreadDot();
        return response.json();
      }
      throw new Error("Something went wrong");
    })
    .catch((error) => console.error(error));
}

document.addEventListener("DOMContentLoaded", function () {
  const markAllAsReadLink = document.getElementById("mark-all-as-read");
  if (markAllAsReadLink) {
    markAllAsReadLink.addEventListener("click", function (e) {
      e.preventDefault();
      const url = markAllAsReadLink.getAttribute("href");
      markAllAsRead(url);
    });
  }
});
